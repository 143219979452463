import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IdentityError } from './models/indentity-errors.model';
import { NewPasswordRequestModel } from './models/account/new-password.models';
import { PasswordPolicyModel } from './models/account/password-policy.model';
import { ResetPasswordRequestModel } from './models/account/reset-password-request.model';
import { SendTfaCodeRequestModel } from './models/profile/tfa-recovery-codes.models';
import { SignInRequestModel, SignInResponseModel } from './models/account/sign-in.models';
import { SsoSingleSignOnRequestModel, SsoSingleSignOnResponseModel } from './models/account/sso-sign-in.models';
import { VerifyTwoFactorCodeRequestModel } from './models/account/verify-tfa-code-request.model';
import { AccountType } from './state/account/account.reducer';
import { SignOutModel } from './models/account/sso-sign-out.model';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private httpClient = inject(HttpClient);

  checkAuth(): Observable<AccountType> {
    return this.httpClient.get<AccountType>(`/account/me`);
  }

  loadExternalSignOnStatus(): Observable<SignInResponseModel> {
    return this.httpClient.get<SignInResponseModel>('/account/sign-in-status');
  }

  signIn(signInFormRequestModel: SignInRequestModel): Observable<SignInResponseModel> {
    return this.httpClient.post<SignInResponseModel>(`/account/sign-in`, signInFormRequestModel);
  }

  signInViaSingleSignOn(singleSignOnRequestModel: SsoSingleSignOnRequestModel): Observable<SsoSingleSignOnResponseModel> {
    return this.httpClient.post<SsoSingleSignOnResponseModel>('/account/select-sign-in-method', singleSignOnRequestModel);
  }

  signOut(): Observable<SignOutModel> {
    return this.httpClient.post<SignOutModel>(`/account/sign-out`, {});
  }

  resetPassword(resetPasswordRequestModel: ResetPasswordRequestModel): Observable<void> {
    return this.httpClient.post<void>(`/account/reset-password`, resetPasswordRequestModel);
  }

  verifyResetPasswordToken(userId: number, token: string, recaptchaToken: string): Observable<HttpResponse<{ email: string }>> {
    return this.httpClient.post<{ email: string }>(
      `/account/verify-reset-password-token`,
      { userId, token, recaptchaToken },
      { observe: 'response' },
    );
  }

  setNewPassword(newPasswordRequestModel: NewPasswordRequestModel): Observable<void> {
    return this.httpClient.post<void>(`/account/set-new-password`, newPasswordRequestModel);
  }

  sendTwoFactorCode(sendTwoFactorCodeRequestModel: SendTfaCodeRequestModel): Observable<void> {
    return this.httpClient.post<void>('/account/send-two-factor-code', sendTwoFactorCodeRequestModel);
  }

  verifyToFactorCode(verifyTwoFactorCodeRequestModel: VerifyTwoFactorCodeRequestModel): Observable<void> {
    return this.httpClient.post<void>('/account/verify-two-factor-code', verifyTwoFactorCodeRequestModel);
  }

  fetchPasswordPolicyStrength(): Observable<PasswordPolicyModel> {
    return this.httpClient.get<PasswordPolicyModel>('/account/password-policy-strength');
  }

  getIdentityErrors(errorId: string): Observable<IdentityError> {
    return this.httpClient.get<IdentityError>(`/identity-errors`, {
      params: new HttpParams({
        fromObject: {
          errorId,
        },
      }),
    });
  }
}
